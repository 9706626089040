import React, {useState} from "react";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import './StockPage.module.css';

import MoodBar from "../../components/stock/MoodBar/MoodBar";
import FilterBar from "../../components/stock/FilterBar/FilterBar";
import CardLayout from "../../components/stock/CardLayout/CardLayout";
import { Link } from 'react-router-dom';

const StockPage = () => {
    const [originalData, setOriginalData] = useState([]);


    return (
        <div className={styles.container}>
             <Link to="/console">
                <img 
                src="/close.png" 
                alt="Custom Icon"
                style={{
                    position: 'fixed', //Fixed position
                    top: '90px',       // 10px from the top
                    right: '70px',     //10px to the right
                    width: '40px',     // Set width
                    height: '40px',   
                }}
            />
            </Link>
            <Header />
            <div className={styles.mainArea}>
                <div className={styles.sidebar}>
                    <Sidebar />
                </div>
                <div>

                    <h1 style={{ marginLeft: '50px',
                                 marginTop: '20px',
                                height: '48px',
                                fontWeight: '700',
                                fontSize: '40px'
                 }}>US Stock Market News</h1>
                    <MoodBar />
                    <FilterBar setOriginalData={setOriginalData} newsData={originalData} />
                    {/* Adjust card layout */}
                    <div style={{ 
                            width: '1230px', 

                            backgroundColor: "#fff", 
                            borderRadius: "8px", 
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)",
                            display: 'flex',       
                            flexDirection: 'column', 

                            marginTop: '25px',
                            marginLeft: '60px'
                        }}>  
                        <CardLayout cardsData={originalData} />
                    </div>


                </div>
            </div>
        </div>
    );
}

export default StockPage ;



