import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './CompanyDataComponents.module.css';

import config from '../../config/config';
function CompanyTable({handleSearch}) {
    const [companys, setCompanys] = useState([]);

    useEffect(() => {
        axios.post(`${config.BACKEND_ENDPOINT}/api/v1/financial/company/companyList`)
            .then(response => {
                setCompanys(response.data); // 假设返回的数据是一个数组
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []); // 空依赖数组意味着这个 effect 只在组件挂载时运行一次

    return (
        <table className={styles.companyTable}>
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>grossProfit</th>
                        <th>totalRevenue</th>
                        <th>costOfRevenue</th>
                        <th>totalAssets</th>
                        <th>currentDebt</th>
                        <th>netIncome</th>
                        <th>operatingCashFlow</th>
                    </tr>
                </thead>
                <tbody>
                    {companys.map(company => (
                        <tr key={company.id}>
                            <td onClick={()=>handleSearch(company.symbol)}>{company.name}</td>
                            <td>{company.grossProfit}</td>
                            <td>{company.totalRevenue}</td>
                            <td>{company.costOfRevenue}</td>
                            <td>{company.totalAssets}</td>
                            <td>{company.currentDebt}</td>
                            <td>{company.netIncome}</td>
                            <td>{company.operatingCashflow}</td>
                        </tr>
                    ))}
                </tbody>
        </table>
    );
}

export default CompanyTable;