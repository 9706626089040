import React from 'react';
import styles from './NewsCard.module.css';
import { Link } from 'react-router-dom';


const defaultImage = 'NoPFound.png'

function NewsCard({ title, imageSrc, description, tags, date, sentiment, url}) {
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };



  return (
    <div className={styles.newsCard}>
      <h2 className={styles.newsCardTitle}>
         <Link to={url} target="_blank" rel="noopener noreferrer">{title}</Link>
        {title}</h2>


      <img src={imageSrc || defaultImage} alt="News Thumbnail" />
      

      <p className={styles.newsCardDescription}>
      <Link to={url} target="_blank" rel="noopener noreferrer">{description}</Link>
        {description}</p>
      <div className='dd'>
        
      {tags.map(tag => {
        const tagClass = styles["tag" + tag];
        const tagStyle = tagClass ? { className: tagClass } : { style: { backgroundColor: getRandomColor() }};
        return (
          <span key={tag} className={`${styles.tag} ${tagStyle.className || ''}`} style={tagStyle.style}>
            {tag}
          </span>
        );
      })}

      </div>
      <div className={styles.footer}>
        <span className={styles.date}>{date}</span>
        <span className={`${styles.sentiment} ${styles["footer" + sentiment]}`}>{sentiment}</span>
      </div>
    
    </div>
  );
}

export default NewsCard;
