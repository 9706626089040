import React, { useState} from 'react';
import styles from "./FilterBar.module.css"

import moment from 'moment';
import config from '../../../config/config.js';
import DateTimeConverter from "../DateTimeConverter/DateTimeConverter";


const backendUrl = `${config.BACKEND_ENDPOINT}/api/v1/financial/news`;

function FilterBar({setOriginalData}) { 


  const [topic, setTopic] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [sorting, setSorting] = useState('Latest');
  const [limit, setLimit] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [ticker, setTicker] = useState('');
  const [label, setLabel] = useState('');

  const openModal = () => setShowModal(true);




  const updateFilters = (backendData) => {

    let filteredData = backendData;

  if (["10", "20", "50"].includes(limit)) {
    filteredData = filteredData.slice(0, Number(limit));
  }

  if (topic === "single" || topic === "multiple") {
    filteredData = filteredData.filter(item =>
      topic === "single" ? item.tags.length === 1 : item.tags.length !== 1
    );
  }




  if (sorting === "desc" || sorting === "asc") {
    filteredData.sort((a, b) => {
      const dateA = parseInt(a.date.split('.').join(''), 10);
      const dateB = parseInt(b.date.split('.').join(''), 10);
      return sorting === "desc" ? dateA - dateB : dateB - dateA;
    });
  } else if (sorting === "relevant") {
    filteredData.sort((a, b) => b.score - a.score);
  }

  return filteredData;
 }    
  

  const handleAIInsightClick = () => {
    console.log('AI Insight clicked');
    handleSearch();
    
  };

  const convertSourceData = (sourceData) => {
    const convertedArray = sourceData.map(item => ({
        title: item.title,
        banner_image: item.banner_image,
        description: item.summary,
        source: item.source,
        url: item.url,
        tags: item.ticker_sentiment?.map(item3 => item3.ticker) ?? [],
        date: DateTimeConverter(item.time_published),
        score:item.overall_sentiment_score,
        label: item.overall_sentiment_label,
        ticker_sentiment: item.ticker_sentiment
    }));
    return convertedArray;
};

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleSearch = async () => {

    const tickets = [searchTerm.toUpperCase()];

    const now = moment();
    let timeFrom = now.format('YYYY-MM-DDTHH:mm');
    let timeTo = now.format('YYYY-MM-DDTHH:mm');

    timeFrom =  now.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm');
    const yesterday = now.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm');
    const lastWeek = now.clone().subtract(1, 'weeks').format('YYYY-MM-DDTHH:mm');
    const lastMonth = now.clone().subtract(1, 'months').format('YYYY-MM-DDTHH:mm');

    const timeFrameChecks = {
      'today': () => timeFrom = yesterday,
      'week': () => timeFrom = lastWeek,
      'month': () => timeFrom = lastMonth
    };

  if (timeFrame in timeFrameChecks) {
    timeFrameChecks[timeFrame]();
  }

    
    // const dataToSend = {
    //   "tickers": tickets,
    //   "time_from": timeFrom,
    //   "limit": "50"
    // };
    const dataToSend = {
      "tickers": tickets,
      "time_from": timeFrom,
      "time_to": timeTo
    };

    console.log("dataToSend")
    console.log(dataToSend)

    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.ok) {

        const backendData = await response.json();
        const RawData = backendData.feed;

        const originalData1 = convertSourceData(RawData);
        
        console.log('formed Data:', originalData1);

        const filteredData =  updateFilters(originalData1);
        
        setOriginalData(filteredData);
        let averageScores = calculateAverageRelevanceScores(filteredData);
        

        const average = averageScores[tickets];


        const label = sentimentLabel(average);

        setTicker(tickets);
        setLabel(label);
        openModal( tickets, label);
      } else {
        console.error('Server responded with a non-200 status code');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  // Pop-up function
  function Modal({ show, ticker, label }) {
    if (!show) {
      return null;
    }
    const sentense = generateAction(ticker, label);

    return (
      <div className={styles.modal_backdrop}>
        <div className={styles.modal_content}>
          <p>Based on the Sentiment Scores, We Suggest Your Portfolio Allocation as:</p>
          <ul>
              <li>{sentense}</li>
          </ul>
          <p>Investing involves risks. Consult a financial advisor for personalized advice.</p>
        </div>
      </div>
    );
  }


  function calculateAverageRelevanceScores(data) {
    let scores = {};
    let counts = {};

    data.forEach(item => {
//Check the ticket_ Is sentiment empty
      if (item.ticker_sentiment && item.ticker_sentiment.length > 0) {
          item.ticker_sentiment.forEach(tickerSentiment => {
              let ticker = tickerSentiment.ticker;
              // console.log("ticker: "+ ticker)
              let score = parseFloat(tickerSentiment.relevance_score);
              // console.log("score:" + score)
              if (!scores[ticker]) {
                  scores[ticker] = 0;
                  counts[ticker] = 0;
              }

              scores[ticker] += score;
              counts[ticker]++;
          });
      }
  });

    let averageScores = {};
//Calculate the average value and store it in a new object
    for (let ticker in scores) {
        averageScores[ticker] = scores[ticker] / counts[ticker];
    }

    return averageScores;
  }

  function sentimentLabel(x) {
    if (x <= -0.35) {
        return "Bearish";
    } else if (x > -0.35 && x <= -0.15) {
        return "Somewhat-Bearish";
    } else if (x > -0.15 && x < 0.15) {
        return "Neutral";
    } else if (x >= 0.15 && x < 0.35) {
        return "Somewhat_Bullish";
    } else if (x >= 0.35) {
        return "Bullish";
    }
  }

  function generateAction(ticker, sentimentLabel) {


    console.log(ticker);
    console.log(sentimentLabel);

    switch (sentimentLabel) {
        case 'Bearish':
            return `${ticker}: Reduce by 20-30%`;
        case 'Somewhat_Bearish':
            return `${ticker}: Reduce by 10-15%`;
        case 'Neutral':
            return `${ticker}: Maintain Current Allocation`;
        case 'Somewhat_Bullish':
            // console.log("work")
            return `${ticker}: Increase by 10-15%`;
        case 'Bullish':
            return `${ticker}: Increase by 20-30%`;
        default:
            return `Sentiment label not recognized`;
    }
}

  return (

    <div>
        <div className={styles.searchContainer}>
            <input
                className={styles.searchInput}
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search Stock Tickers"
            />
             <i className={`fa fa-search ${styles.searchIcon}`}></i> 
        </div>

      <div className={styles.filterBar}>

       <select value={topic} onChange={e => { setTopic(e.target.value);}}>
        <option value="">Topics</option>
        <option value="single">Single Topic</option>
        <option value="multiple">Multiple Topics</option>
      </select>

      <select value={timeFrame} onChange={e => { setTimeFrame(e.target.value);  }}>
        <option value="">Time Frame</option>
        <option value="today">Last Day</option>
        <option value="week">Last Week</option>
        <option value="month">Last Month</option>
      </select>
      
      <select value={sorting} onChange={e => { setSorting(e.target.value); }}>
        <option value="">Sorting</option>
        <option value="asc">Latest</option>
        <option value="desc">Earliest</option>
        <option value="relevant">Most Relevant</option>
      </select>
      
      <select value={limit} onChange={e => { setLimit(e.target.value); }}>
        <option value="">Limit</option>
        <option value="10">9 articles</option>
        <option value="20">18 articles</option>
        <option value="50">45 articles</option>
      </select>
      <button className={styles.aiInsight} onClick={handleAIInsightClick}>AI Insight</button>
    </div>


      <div>
      <Modal show={showModal} ticker={ticker} label={label} /> 
      </div>

    </div>
  );
}


export default FilterBar;
