import React from 'react';
import TagFloat from "../../components/FinanceAI/TagFloat"

const FinancialProductsChecklistQuestion = ({ financialProducts, handleFinancialProductChange }) => {

    const handleTagsChange = (newTags) => {
        handleFinancialProductChange({
            ...financialProducts,
            ...newTags.reduce((acc, tag) => ({ ...acc, [tag]: true }), {})
        });
    };


    return (
        <div style={{ overflow: 'auto'}}>
            <div  className='question-p'>
            <label>Question 5</label>
            </div>
            <div className='question-name'>
            <TagFloat onTagsChange={handleTagsChange} />
            </div>
        </div>
    );
};

export default FinancialProductsChecklistQuestion;
