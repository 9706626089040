import styles from "./CompanyDataComponents.module.css";
import Cards from "./Cards";
import LineChart from "./LineChart";
function calculateDifference(num1, num2) {
    return Math.round((num1 - num2) / num2 * 100);
}

const IncomeStatement=({companyName,annualReportData,setShowAnnualReport})=>{
    return (
       <div>
            <div className={styles.titleWrapper}>
                <h1 className={styles.title}>{companyName} Income Statements</h1>
                <div className={styles.button_style}>
                    <button onClick={()=>setShowAnnualReport(true)}>
                        + view Lastest Annual Report
                    </button>
                </div>
            </div>
            <h4 className={styles.subtitle}>Income Statement Comparsion</h4>
            <div>
                <Cards grossProfit ={calculateDifference(annualReportData[0].grossProfit,annualReportData[1].grossProfit) + '%'} 
                totalRevenue ={calculateDifference(annualReportData[0].totalRevenue,annualReportData[1].totalRevenue )+ '%'} 
                costOfRevenue={calculateDifference(annualReportData[0].costOfRevenue, annualReportData[1].costOfRevenue)+ '%'}
                operatingIncome={calculateDifference(annualReportData[0].operatingIncome,annualReportData[1].operatingIncome ) + '%'}
                netIncome={calculateDifference(annualReportData[0].netIncome, annualReportData[1].netIncome)+ '%'}/>
            </div>
            <h4 className={styles.subtitle}>Cash Flow Comparison</h4>
            <div className={styles.linechart_style}>
                <LineChart annualReportData = {annualReportData}/>
            </div>
       </div>
        
    )
}

export default IncomeStatement;