import Card from './Card';
import styles from "./CompanyDataComponents.module.css";
import grossprofitImage from '../../asset/grossprofit.svg';
import incomeImage from '../../asset/income.svg';
import revenueImage from '../../asset/Revenue.svg'; 
function Cards({grossProfit, totalRevenue,costOfRevenue,operatingIncome,netIncome}) {
    return (
      <div className={styles.cardContainer}>
        <Card title="grossProfit" content={grossProfit} bgColor="rgba(224, 233, 255, 0.72)" Image={grossprofitImage}/>
        <Card title="totalRevenue" content={totalRevenue} bgColor="#E4FFE0" Image={revenueImage}/>
        <Card title="costOfRevenue" content={costOfRevenue} bgColor="#FFE0E0" Image={revenueImage}/>
        <Card title="operatingIncome" content={operatingIncome} bgColor="rgba(136, 255, 141, 0.2)" Image={incomeImage}/>
        <Card title="netIncome" content={netIncome} bgColor="#FFFCE0"Image={incomeImage}/>
      </div>
    );
}
export default Cards;

  