import React, { useState,  useEffect } from "react";

import "./FinanceAIPage2.module.css";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import FinancialInsights from "../../components/FinanceAI/FinancialInsights"
import EmploymentStatusQuestion from "../../components/FinanceAI/EmploymentStatusQuestion"
import FinancialProductsChecklistQuestion from "../../components/FinanceAI/ProductsChecklistQuestion"
import config from '../../config/config.js';
import { useLocation, useNavigate} from 'react-router-dom';


const backendUrl = `${config.BACKEND_ENDPOINT}/api/v1/financial/advice`;

const FinanceAIPage2 = () => {
      // eslint-disable-next-line
    const location = useLocation();
    const navigate = useNavigate();

    const[formData, setFormData] = useState(location.state || {});
    
    const productDescriptions = {
        'Stock': 'Stocks',
        'Treasury bills': 'Treasury bills (T-bills)',
        'EFTs': 'Exchange-traded funds (ETFs)',
        'Cash': 'Emergency funds and Cash',
      };

    const handleFinancialProductChange = (updatedFinancialProducts) => {
    setFormData((prevFormData) => ({
        ...prevFormData,
        financial_products: updatedFinancialProducts,
    }));
    };

    useEffect(() => {
        // console.log('Form data updated:', formData);
    }, [formData]);


const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.financial_products || Object.values(formData.financial_products).every(isSelected => !isSelected)) {
      alert('Please reselect financial product.');
      return;
  }


    // console.log('Submitting the following data to the backend:', formData);


    const financialProductsString = Object.entries(formData.financial_products)
    .filter(([product, isSelected]) => isSelected)
    .map(([product]) => productDescriptions[product] || product)
    .join(', ');

    const dataToSend = {
        risk_type: formData.risk_type,
        age: formData.age,
        annual_income: formData.annualIncome,
        financial_products: financialProductsString
      };
    
    // console.log('Submitting the following data to the backend:', dataToSend);


    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });
  
      if (response.ok) {
        // console.log("final:");
        const backendData = await response.json();
        // console.log('Received backendData:', backendData);
        navigate('/financeai3', { state: { data: backendData } });
      } else {
        console.error('Server responded with a non-200 status code');
      }
    } catch (error) {
      console.error('An error occurred while sending the form data:', error);
    }
  };

  return (
    <div className={styles.container}>
        <Header />
        <div className={styles.mainArea}>
            <div className={styles.sidebar}>
            <Sidebar />
            </div>
            <div className={styles.mainContent}>
                <FinancialInsights/>
                <div style={{ 
                            marginLeft: '40px',
                            width: '90%',
                            maxWidth: '2000px',
                            height: '550px',
                            backgroundColor: "#fff", 
                            padding: "70px",  // inner margins
                            borderRadius: "8px", 
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)",
                            paddingLeft: '150px',

                            display: 'flex',       
                            flexDirection: 'column', 
                            justifyContent: 'space-between',  
                        }}>
                    <h3 style={{color: '#e8dcfc', fontSize: '1.08em'}}>
                        Last 2 questions, almost there!</h3>
                        <EmploymentStatusQuestion />
                        <FinancialProductsChecklistQuestion 
                            handleFinancialProductChange={handleFinancialProductChange} 
                        />

                    <div style={{textAlign: 'center'}}> 
                      <button onClick={handleSubmit}  style={{ width: '100px', padding: '10px 20px', borderRadius: '5px' }}>Next</button>
                     </div>
                  </div>
            </div>
            </div>

    </div>
  );
};

export default FinanceAIPage2;


