import React, { useState } from 'react';

const EmploymentStatusQuestion = () => {
    const [employmentStatus, setEmploymentStatus] = useState(''); //set a default value if needed

    return (
        <div style={{ overflow: 'auto'}}>
            <div  className='question-p'>
            Question 4</div>
            <div className='question-name'>
                <label>What is your current employment status?</label>
                <select value={employmentStatus} onChange={(e) => setEmploymentStatus(e.target.value)}>
                    <option value="Employed">Employed</option>
                    <option value="Self Employed">Self Employed</option>
                    <option value="Retired">Retired</option>
                    <option value="Working part time">Working part time</option>
                    <option value="Not Employed">Not Employed</option>
                </select>
            </div>
        </div>
    );
};

export default EmploymentStatusQuestion;
