import React, { useState } from 'react';

function IncomeQuestion({onChange}) {
    const [annualIncome, setAnnualIncome] = useState(0); 

    const handIncomeChange = (e) =>{
        setAnnualIncome(e.target.value);
        onChange(e.target.value);
    };

    return (
        <div style={{ overflow: 'auto'}}>
            <div  className='question-p'>
            Question 3</div>

            <div className='question-name'>
                <label>What is your annual income?</label>
                <input 
                    type="range" 
                    style={{ 
                        width: '300px',
                        '--webkit-slider-thumb-color': '#4B0082',
                        '--moz-range-thumb-color': '#4B0082'
                    }}
                    value={annualIncome} 
                    min="0" 
                    max="500" 
                    onChange={handIncomeChange}
                />
                <div
                    style={{ 
                        textAlign: 'center',
                        marginTop: '-18px',
                        transform: 'translateX(-15%)',
                        color: '#a9a9a9' 
                    }}
                >{annualIncome}K</div>
            </div>
        </div>
    );
}

export default IncomeQuestion;
