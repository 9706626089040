import React from 'react';
import { Pagination } from 'antd';
import NewsCard from "../NewsCard/NewsCard";
import style from './CardLayout.module.css';

class CardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsData: this.props.cardsData || [],
      page: 1,
    };
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    if (this.props.cardsData) {
      this.setState({ cardsData: this.props.cardsData }, () => {
        console.log(this.state.cardsData); 
      });
    }
  }

  onPageChange(page) {
    this.setState({ page });
  }

  render() {
   
    const cardsData = this.props.cardsData || []

    const { page } = this.state
    

    const startIdx = (page - 1) * 9;
    const endIdx = page * 9;


    console.log(typeof(cardsData))

    return (
    <div class="layout-wrapper">

      <div className={style.root}>
        <div className={style.container}>
          {cardsData.slice(startIdx, endIdx).map((item, index) => (
            <NewsCard
            key={item.id || index} 
            title={item.title}
            imageSrc={item.banner_image} 
            description={item.description} 
            tags={item.tags} 
            date={item.date} 
            sentiment={item.label} 
            url = {item.url}
            /> 
          ))}
        </div>
        <footer className={style.footer}>
          <Pagination 
            defaultCurrent={1} 
            current={page}
            total={cardsData.length}
            onChange={this.onPageChange}
          />
        </footer>
      </div>

      </div>


    );
  }
}

export default CardLayout;
