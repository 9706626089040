import React from "react";
import * as d3 from "d3";

const Slice = ({ slice, sliceColor, onMouseOverSlice }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const radius = 130;
  
  const arc = d3.arc()
    .innerRadius(radius * 0.7)
    .outerRadius(isHovered ? radius * 1.1 : radius)
    .padAngle(0.01)
    .cornerRadius(2)(slice);

  return (
    <path
      d={arc}
      fill={sliceColor}
      onMouseOver={() => {
        setIsHovered(true);
        onMouseOverSlice(slice.data); 
      }}
      onMouseOut={() => setIsHovered(false)} 
    />
  );
};

export const SingleLayerDonutChart = ({ data, onSliceHover }) => {
  const height = 380;
  const width = 600;
  const colorScale = d3.scaleOrdinal(d3.schemeCategory10);


  let pie = d3.pie().value(d => d.value)(data);

  return (
    <svg height={height} width={width}>
      <g transform={`translate(${width / 2},${height / 2})`}>
        {pie.map((slice, index) => {
          const name = data[index].name; 
          return (
            <Slice
              key={name}
              slice={slice}
              sliceColor={colorScale(index)}
              onMouseOverSlice={() => onSliceHover(slice.data)}
            />
          );
        })}
      </g>
    </svg>
  );
};