import IncomeStatement from "./IncomeStatement";
import AnnualRepot from "./AnnualReport";
import React from "react";
import { useState } from "react";

const CompanyInfo=({companyName,annualReportData})=>{
    const [showAnnualReport, setShowAnnualReport] = useState(false);
    return (
        <div>
            {showAnnualReport? <AnnualRepot companyName={companyName} annualReportData = {annualReportData[0]} /> : <IncomeStatement companyName={companyName} annualReportData = {annualReportData} setShowAnnualReport ={setShowAnnualReport}/>}
        </div>
    )
}

export default CompanyInfo;