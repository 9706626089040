import React, { useState } from 'react';
import './QuestionName.css'
function AgeQuestion({onChange}) {
    const [age, setAge] = useState(''); 

    const handleAgeChange = (e) => {
        setAge(e.target.value);
        onChange(e.target.value); 
    };

    return (
        <div style={{ overflow: 'auto' }}> 
            <div  className='question-p'>
            Question 2</div>
            
            <div className='question-name'>
                <label>What is your current age?</label>
                <input 
                    type="text" 
                    value={age} 
                    onChange={handleAgeChange} 
                    placeholder="Your age is..." 
                />
            </div>
        </div>
    );
}

export default AgeQuestion;
