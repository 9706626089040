import React, { useState } from "react";

import "./FinanceAIPage4.module.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import FinancialInsights from "../../components/FinanceAI/FinancialInsights"
import './FinanceAIPage4.module.css';
import { SingleLayerDonutChart } from "../../components/FinanceAI/TwoLayerDonutChart";
import { useLocation } from 'react-router-dom'; 


const FinanceAIPage4 = () => {
    const location = useLocation();
    const backendData = location.state?.data;

    const containerStyle = {
        float: "left",
        maxWidth: '35%',
        wordBreak: "break-all",
        fontSize: "14px",
        fontWeight: 'bold',
        backgroundColor: "#fff",
        padding: "15px",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)",
    };

    const pStyle = {
        marginTop: '10px'
    };

    const ulStyle = {
        paddingLeft: '30px',
        marginTop: '20px'
    };



    const getDescriptionForKey = (key) => {
        switch (key) {
          case "Exchange-traded funds (ETFs)":
            return "growth-focused ETFs";
          case "Stocks":
            return "dividend stocks";
          case "Treasury bills (T-bills)":
            return "short-term safety with Treasury bills";
          case "Cash":
            return "cash for emergencies";
          
          default:
            return key.toLowerCase();
        }
      };

      const investmentDescriptions = backendData && backendData['data']
      ? Object.entries(backendData['data']).map(([key, value]) => {
          const description = getDescriptionForKey(key);
          return `${value}% in ${description}`;
      })
      : [];
  
 
        const chartData = backendData && backendData['data']
        ? Object.entries(backendData['data']).map(([key, value]) => ({
            name: getDescriptionForKey(key),
            value: Number(value)
        }))
     : [];

      const [activeSlice, setActiveSlice] = useState({ name: null, value: null });

      const handleSliceHover = (sliceData) => {
        setActiveSlice(sliceData);
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.mainArea}>
                <div className={styles.sidebar}>
                    <Sidebar />
                </div>

                <div className={styles.mainContent}>
                <FinancialInsights/>
                    <div style={{ 
                            marginLeft: '50px',
                            width: '90%',
                            maxWidth: '2000px',
                            height: '550px',
                            backgroundColor: "#fff", 
                            padding: "25px",  
                            borderRadius: "8px", 
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)"
                        }}>
                            
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <h2 style={{ color: '#4B0082', fontSize: '32px' }}>Personalized Financial Report</h2>
                            </div>

                            <div style={{ float: "left", marginRight: '12px' }}>

                                <SingleLayerDonutChart data={chartData} onSliceHover={handleSliceHover} />
                                
                                {activeSlice.name !== null && (
                                <div style={{ textAlign: 'center' }}>
                                    <p style={{ fontWeight: 'bold' }}>{activeSlice.name} - {activeSlice.value}%</p>
                                </div>
                                )}

                            </div>
                        
                            <div style={{ marginTop: '72px' }}>
                                    <div style={containerStyle}>
                                        <p style={pStyle}> Assess and choose a diversified portfolio of financial products that match your risk tolerance and investment goals. Options for fund allocation include:</p>
                                        <ul style={ulStyle}>
                                            {investmentDescriptions.map((desc, index) => (
                                                <li key={index}>{desc}</li>
                                            ))}
                                        </ul>
                                    </div>
                            </div>
                         
                    </div>
                    </div>
                </div>
            </div>
    );
}

export default FinanceAIPage4;



