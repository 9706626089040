import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis,VictoryLegend,VictoryLabel  } from 'victory';

function getMonthAbbreviation(dateString) {
    const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // 解析日期字符串
    const date = new Date(dateString);

    // 获取月份索引
    const monthIndex = date.getMonth();

    // 返回对应的月份缩写
    return monthAbbreviations[monthIndex];
}

function getMaxAndMin(data1, data2) {
    const allValues = [...data1, ...data2].map(d => d.value);
    const maxValue = Math.max(...allValues);
    const minValue = Math.min(...allValues);
    return { maxValue, minValue };
}

function getYearFromDate(dateString) {
    return new Date(dateString).getFullYear().toString();
}

function LineChart({annualReportData}) {
    
    const data1 = [
        { month: getMonthAbbreviation(annualReportData[3].fiscalDateEnding), value: annualReportData[0].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[2].fiscalDateEnding), value: annualReportData[1].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[1].fiscalDateEnding), value: annualReportData[2].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[0].fiscalDateEnding), value: annualReportData[3].operatingCashflow},
    ];

    const data2 = [
        { month: getMonthAbbreviation(annualReportData[7].fiscalDateEnding), value: annualReportData[4].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[6].fiscalDateEnding), value: annualReportData[5].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[5].fiscalDateEnding), value: annualReportData[6].operatingCashflow},
        { month: getMonthAbbreviation(annualReportData[4].fiscalDateEnding), value: annualReportData[7].operatingCashflow},
    ];

    const { maxValue, minValue } = getMaxAndMin(data1, data2);
    // 调整最大值和最小值
    const adjustedMax = maxValue + 500000000;
    const adjustedMin = minValue - 500000000;

    const yearForData1 = getYearFromDate(annualReportData[0].fiscalDateEnding);
    const yearForData2 = getYearFromDate(annualReportData[4].fiscalDateEnding);
    return (
        <VictoryChart 
            width={300}  // 设置图表的宽度
            height={180} // 设置图表的高度
            domain={{ y: [adjustedMin, adjustedMax] }}
        >
            <VictoryAxis
                // 配置 X 轴，例如设置标签
                style={{
                    tickLabels: { fontSize: 5, fill: "gray" }
                }}
                tickFormat={(x) => `${x}`}
            />
            <VictoryAxis
                style={{
                    tickLabels: { fontSize: 5, fill: "gray" }
                }}
                dependentAxis
                tickFormat={(y) => (`$${y / 1000000000}B`)}
            />
            <VictoryLine
                data={data1}
                x="month"
                y="value"
                interpolation="natural"
                style={{ data: { stroke: "#3E00C2", strokeWidth: 1 } }}
            />

            <VictoryLine
                data={data2}
                x="month"
                y="value"
                interpolation="natural"
                style={{ data: { stroke: "#08D110" , strokeWidth: 1} }}
            />

            <VictoryLegend
                x={200} y={35} // 定位图例
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{labels: { fontSize: 5 } }}
                data={[
                    { name: yearForData1, symbol: { fill: "#3E00C2", type: "circle" } },
                    { name: yearForData2, symbol: { fill: "#08D110", type: "circle" } }
                ]}
            />
            <VictoryLabel 
                text="Operating Cashflow" 
                x={30} y={45} // 标签的位置
                textAnchor="start" // 文本对齐方式
                style={{ fontSize: 3, fill: "#5321CA" }}
            />
        </VictoryChart>
    );
}

export default LineChart;