import React, { useState } from "react";
import styles from "./AnalyzeExpense.module.css";
import DownloadIcon from "../../asset/download.svg";
import EditIcon from "../../asset/edit.svg";

function DownloadButton({ presignedUrl }) {
  const [filename, setFilename] = useState("filename.txt");
  const [isEditing, setIsEditing] = useState(false);

  if (!presignedUrl) {
    console.error("Presigned URL not provided.");
    return null;
  }

  return (
    <div className={styles.downloadComponentcontainer}>
      <div className={styles.filenameContainer}>
        {isEditing ? (
          <input
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            onBlur={() => setIsEditing(false)}
            className={styles.filenameInput}
            autoFocus
          />
        ) : (
          <span className={styles.filenameDisplay} onClick={() => setIsEditing(true)}>
            {filename}
          </span>
        )}
        <img
          src={EditIcon}
          alt="Edit Filename"
          className={styles.editIcon}
          onClick={() => setIsEditing(!isEditing)}
        />
      </div>
      <div className={styles.downloadButtonWrap}>
      <a 
        href={presignedUrl}
        download={filename}
        className={styles.downloadButtonContainer}
      >
        <img src={DownloadIcon} alt="Download Icon" className={styles.downloadIcon} />
        <span className={styles.downloadText}>
          Download
        </span>
      </a>
      </div>
    </div>
  );
}

export default DownloadButton;
