import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { HARDCODE_USER } from "../../constants/constants";
import TextInput from "../../components/transcribe/textInput";
import Loading from "../../components/transcribe/loading";
import LanguageChoose from "../../components/transcribe/LanguageChoose";
import "./summary_page.css";


import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import { Link } from "react-router-dom";

const SummaryPage = () => {
    const [text,setText] = useState("")
    const [loading,setLoading] = useState(false);

    const selects = [
        {label: 'English'},
        {label: 'Chinese'},
        {label: 'German'},
    ];
    const user = useSelector((state) => state.auth.user || HARDCODE_USER);
    const credit = useSelector((state) => state.auth.credit || HARDCODE_USER.credit);
    let show = <TextInput text={text} setText={setText} setLoading={setLoading}/>;
    if(loading){
        show = <Loading />;
    }

    return (<div className={styles.container}>
        <Header />
        <div className={styles.mainArea}>
        <div className={styles.sidebar}>
            <Sidebar userCredit={credit} userName={user.username}  />
        </div>
        <div className={styles.textExtractPageArea}>
            {/*  Navigation links topleft */}
            <div className={styles.dashboardText}>
                <Link to="/console" className={styles.dashboardLink}>
                    Dashboard
                </Link>
                <span>/</span>
                <Link to="/transcribe" className={styles.textracterLink}>
                    Transcribe
                </Link>
            </div>
            <div className="textExtractContainer">
                <h1 className='defaultH1'>Summary</h1>
                <h2 className="defaultH2">Accurately summarize your text with your online summarize</h2>
                <LanguageChoose selects = {selects}/>
                {show}
            </div>
        </div>
        </div>
    </div>
    ) 
}

export default SummaryPage;