import React, { useState } from "react";
import styles from "./CompanyDataComponents.module.css";
import searchIcon from "../../asset/Search.svg";
import closeIcon from "../../asset/Close.svg";
import data from "../../data/NyseListed.json"
function  CompanySearchBar({ placeholder, handleSearch}) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = async (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      setFilteredData([]);
      return;
    }

    try {
      const apiData = data;
      const symbols = apiData.map(item => item["Symbol"]);
      const newFilter = symbols.filter(symbol => 
        symbol.toLowerCase().includes(searchWord.toLowerCase())
      );
      console.log(newFilter);
      setFilteredData(newFilter);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const handleItemClick = (title) => {
    handleSearch(title);
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className={styles.search}>
      <div className={styles.searchInputs}>
        <input
          type="text"
          className={styles.searchInputbar}
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        <div className={styles.searchIcon}>
          {filteredData.length === 0 ? (
            <img src={searchIcon} alt="Search Icon" onClick={() => handleItemClick(wordEntered)}/>
          ) : (
            <img
              src={closeIcon}
              alt="Close Icon"
              id="clearBtn"
              onClick={clearInput}
            />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className={styles.dataResult}>
          {filteredData.slice(0, 15).map((value) => {
            return (
              <div onClick={() => handleItemClick(value)}>{value}</div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CompanySearchBar;
