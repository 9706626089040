import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';
import React, { useState } from 'react';
import BigNumber from 'bignumber.js';

function Histogram({currentAssets,inventory, intangibleAssets, goodwill}) {
  const c = new BigNumber(currentAssets).toNumber();
  const i = new BigNumber(inventory).toNumber();
  const inta = new BigNumber(intangibleAssets).toNumber();
  const good = new BigNumber(goodwill).toNumber();
  const data = [
    { prop: 1, earnings: c},
    { prop: 2, earnings: i},
    { prop: 3, earnings: inta },
    { prop: 4, earnings: good}
  ];
  const [style,] = useState({ data: { fill: "#853EFA" } });
  return (
    <VictoryChart domainPadding={20} >
      <VictoryAxis
        tickValues={[1, 2, 3, 4]}
        tickFormat={["Current Assets", "Inventory", "Intangible Assets", "Goodwill"]}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={(x) => (`$${x / 1000000000}B`)}
      />
      <VictoryBar
        data={data}
        x="prop"
        y="earnings"
        style={style}
      />
    </VictoryChart>
  );
}

export default Histogram;