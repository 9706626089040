import { SIGNUP_SUCCESS, SIGNUP_FAILURE, SIGNIN_FAILURE, SIGNIN_SUCCESS, SET_ERROR, CLEAR_ERROR,REDUCT_SUCCESS,REDUCT_FAILURE } from '../actions/authActions';

const initialState = {
  user: null,
  error: null,
  isLoggedIn:false,
  credit: null,
  email:null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        isLoggedIn: true,
        credit: action.credit,
        email:action.email
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        user: null,
        error: action.error,
        isLoggedIn: false
      };
    case SIGNIN_SUCCESS:
    return {
        ...state,
        user: action.payload,
        error: null,
        isLoggedIn: true,
        credit: action.credit,
        email:action.email
    };
    case SIGNIN_FAILURE:
    return {
        ...state,
        user: null,
        error: action.error,
        isLoggedIn: false
    };
    case SET_ERROR:
        return { 
        ...state, 
        error: action.payload 
    };
    case CLEAR_ERROR:
        return { 
        ...state, 
        error: null 
        };

    case  REDUCT_SUCCESS:
      return {
        ...state,
        credit:action.credit
      }

    case REDUCT_FAILURE:
      return{
        ...state,
        error: action.error
      }

    default:
      return state;
  }
};
