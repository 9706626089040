import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './WelcomePage';
import SignUpPage from './pages/signup_page/signup_page'
import SignInPage from './pages/signin_page/signin_page';
import HealthCheckPage from './pages/health_check_page/health_check_page';
import TranscribePage from "./pages/transcribe_page/transcribe_page"
import ConsolePage from './pages/ConsolePage/ConsolePage';
import AnalyzeExpensePage from './pages/AnalyzeExpensePage/AnalyzeExpensePage'
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import PollyPage from './pages/PollyPage/PollyPage';
import SummaryPage from './pages/SummaryPage/summary_page';
import FinancePage1 from './pages/FinanceAlPage1/FinanceAIPage1'
import FinancePage2 from './pages/FinanceAlPage2/FinanceAIPage2'
import FinancePage3 from './pages/FinanceAlPage3/FinanceAIPage3'
import FinancePage4 from './pages/FinanceAlPage4/FinanceAIPage4'
import DetectDocumentTextPage from './pages/DetectDocumentTextPage/DetectDocumentTextPage';
import CompanyDataPage from './pages/CompanyDataPage/CompanyDataPage';

import Stock from './pages/StockPage/StockPage'
import 'font-awesome/css/font-awesome.min.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/health" element={<HealthCheckPage />} />
          <Route path="/console" element={<ConsolePage/>} />
          <Route path="/analyze-expense" element={<AnalyzeExpensePage/>} />
          <Route path="/detect-document-text" element={<DetectDocumentTextPage/>} />
          <Route path="/console" element={<ConsolePage/>}/>
          <Route path="/transcribe" element={<TranscribePage/>} />
          <Route path="/checkout" element={<CheckoutPage/>}/>
          <Route path="/polly" element={<PollyPage/>}/>
          <Route path="summary" element= {<SummaryPage/>}/>
          <Route path="/FinanceAI1" element={<FinancePage1/>}/>
          <Route path="/FinanceAI2" element={<FinancePage2/>}/>
          <Route path="/FinanceAI3" element={<FinancePage3/>}/>
          <Route path="/FinanceAI4" element={<FinancePage4/>}/>
          <Route path="/financial/company" element={<CompanyDataPage/>}/>
          <Route path="/Stock" element={<Stock/>}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;


