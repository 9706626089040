import React from "react";
import styles from "./DetectDocument.module.css";
import DownloadIcon from "../../asset/download.svg";

function DownloadButton({ presignedUrl }) {
  if (!presignedUrl) {
    console.error("Presigned URL not provided.");
    return null;
  }

  return (
    <div className={styles.downloadComponentcontainer}>
      <a 
        href={presignedUrl}
        download 
        className={styles.downloadButtonContainer}
      >
        <img src={DownloadIcon} alt="Download Icon" className={styles.downloadIcon} />
        <span className={styles.downloadText}>
          Download
        </span>
      </a>
    </div>
  );
}

export default DownloadButton;
