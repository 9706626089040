import React, { useState }  from "react";

import "./FinanceAIPage1.module.css";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import FinancialInsights from "../../components/FinanceAI/FinancialInsights"
import RiskToleranceQuestion from "../../components/FinanceAI/RiskToleranceQuestion"
import AgeQuestion from "../../components/FinanceAI/AgeQuestion"
import IncomeQuestion from "../../components/FinanceAI/IncomeQuestion"

import {useNavigate} from 'react-router-dom';

const FinanceAIPage1 = () => {

    const [formData, setFormData] = useState({
        "risk_type": "aggressive",
        "age": 18,
        "financial_products_total": ""
    });

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // Check if age or annualIncome is not filled
        if (!formData.age || !formData.annualIncome) {
            alert('Please fill in both Age and Annual Income.');
            return;
        }

            // Check if age is between 18 and 100
        if (formData.age < 18 || formData.age > 100) {
            alert('Age must be between 18 and 100.');
            return;
        }

        // Navigate to the next page
        navigate('/financeai2', { state: formData });
    }


    const handleRiskToleranceChange = (risk_type) => {
        setFormData(prevFormData => ({
          ...prevFormData,
          risk_type
        }));
      };

    const handleAgeChange = (age) => {
    setFormData(prevFormData => ({
        ...prevFormData,
        age
    }));
    };

    const handleIncomeChange = (annualIncome) => {
    setFormData(prevFormData => ({
        ...prevFormData,
        annualIncome
    }));
    };


  return (
      <div className={styles.container}>
          <Header />
          <div className={styles.mainArea}>
              <div className={styles.sidebar}>
                  <Sidebar />
              </div>
              <div className={styles.mainContent}>
                  <FinancialInsights/>
                  <div style={{ 
                            marginLeft: '40px',
                            width: '90%',
                            maxWidth: '2000px',
                            height: '550px',
                            backgroundColor: "#fff", 
                            padding: "60px",  
                            borderRadius: "8px", 
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)",
                            paddingLeft: '150px',

                            display: 'flex',       
                            flexDirection: 'column', 
                            // justifyContent: 'space-between',  
                        }}>

                        <h3 style={{ 
                                color: '#e8dcfc', 
                                fontSize: '1.08em',
                                marginBottom: '80px', 
                            }}>Total 5 questions within one minute</h3>
                        
                        <form onSubmit={handleSubmit} style={{
                            marginTop: '-20px', 
                        }}>
                            <div style={{ marginBottom: '40px' }}> 
                                <RiskToleranceQuestion onChange={handleRiskToleranceChange} />
                            </div>
                            <div style={{ marginBottom: '40px' }}> 
                                <AgeQuestion onChange={handleAgeChange}/>
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <IncomeQuestion onChange={handleIncomeChange}/>
                            </div>

                            <div style={{textAlign: 'center'}}> 
                            <button type="submit" style={{ width: '100px', padding: '10px 20px', borderRadius: '5px' }}>Next</button>
                            </div>
                        </form>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default FinanceAIPage1;
