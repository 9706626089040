import React from 'react';

const ProgressBar = ({ loadingProgress }) => {
  return (
    <div>
      <div style={{ textAlign: 'left', marginBottom: '5px', fontWeight: 'bold' }}>
        Generating
      </div>

      <div style={{ 
        backgroundColor: '#e1e1e1', 
        height: '3px', 
        borderRadius: '4px', 
        position: 'relative', 
        width: '500px', 
        maxWidth: '600px' 
      }}>
        <div style={{
          width: `${loadingProgress}%`,
          backgroundColor: '#563d7c',
          height: '100%',
          borderRadius: '4px',
        }}></div>
        <span style={{ position: 'absolute', right: '2px', top: '-25px', color: '#a8a2d4', fontWeight: 'bold'}}>
          {loadingProgress}%
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
