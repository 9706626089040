
export const cardsData = [
  {
    label: 1,
    category: "Productive",
    toolName: "Text Extract",
    description: "Convert PDFs, JPGs to Readable Text",
    action: "Use",
    cost: "10/page",
    url: "/text-extract",
  },
  {
    label: 2,
    category: "Productive",
    toolName: "Transcribe",
    description: "Transcribe audio to text",
    action: "Use",
    cost: "20/min",
    url: "/transcribe",
  },
  {
    label: 3,
    category: "Productive",
    toolName: "Text-To-Speech",
    description: "Read the Input Content",
    action: "Use",
    cost: "Free",
    url: "/polly",
  },
  {
    label: 4,
    category: "Productive",
    toolName: "Receipt to CSV",
    description: "Convert invoices and receipts into CSV.",
    action: "Use",
    cost: "10/page",
    url: "/analyze-expense",
  },
  {
    label: 5,
    category: "Productive",
    toolName: "Document Text Extract",
    description: "Automatically identify and extract text from documents",
    action: "Use",
    cost: "10",
    url: "/detect-document-text",
  },
  {
    label:6 ,
    category: "Productive",
    toolName: " Summary",
    description: "Summary the text you type",
    action: "Use", 
    cost: "Free", 
    url: "/summary"
  },
  {
    label: 7,
    category: "Productive",
    toolName: "Company Data",
    description: "Description for Tool Name 6",
    action: "Use",
    cost: "Free",
    url: "/financial/company"
  },
  {
    label:8,
    category: "Financial",
    toolName: "Stock",
    description: "Recommend News related to Stock",
    action: "Use",
    cost: "Free",
    url: "/stock"
  },
  {
    label:9 ,
      category: "Financial",
      toolName: "FinancialAI",
      description: "Recommended Property Distribution",
      action: "Use", 
      cost: "Free",
      url: "/financeai1"
    },
];
