import React, { useState } from 'react';
function RiskToleranceQuestion({onChange}) {
    const [riskTolerance, setRiskTolerance] = useState('');  

    const handleChange = (e) => {
        setRiskTolerance(e.target.value);
        onChange(e.target.value);
    }
    return (
        <div style={{ overflow: 'auto' }}> 
            <div  className='question-p'>
                Question 1
            </div>

            <div className='question-name'>
                <label>What is your risk tolerance type?</label>
                <select value={riskTolerance} onChange = {handleChange}>
                    <option value="Risk Averse">Risk Averse</option>
                    <option value="Balanced">Balanced</option>
                    <option value="Aggressive">Aggressive</option>
                </select>
            </div>
        </div>
    );
}

export default RiskToleranceQuestion;
