import React from 'react';

const FinancialInsights = () => {
    return (
        <>
            <div style={{marginTop: '20px', paddingLeft: '25px', fontSize: '18px', 
                        maxWidth: '95%', margin: 'auto', marginBottom: '15px'}}>
                <h1 style={{ fontSize: '34px', marginTop: '30px'  }}>Tailored Financial AI Insights</h1>
                
                <p style={{ fontSize: '16px' ,marginBottom: '20px'}}>
                    Drawing from your risk tolerance, income level, preferred financial products, and other pertinent details, we are poised to provide you with astute financial insights underpinned by artificial intelligence analysis.
                </p>
            </div>
        </>
    );
}

export default FinancialInsights;
