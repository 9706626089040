// DateTimeConverter.js

function DateTimeConverter( dateTimeString ) {
    if (typeof dateTimeString === 'string' && dateTimeString.length >= 10) {
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
    
        const date = new Date(`${year}-${month}-${day}`);
    
        const yearValue = date.getFullYear();
        const monthValue = date.getMonth() + 1;
        const dayValue = date.getDate() + 1;

        const formattedDate = `${yearValue}.${monthValue.toString().padStart(2, '0')}.${dayValue.toString().padStart(2, '0')}`;
        return formattedDate;
      } else {
        return '';
      }
    }

export default DateTimeConverter;
