import React from 'react';
import { useState, useCallback } from 'react';
import './TagFloat.css';

function TagFloat({onTagsChange}) {
  const [tags, setTags] = useState(['EFTs']);

  const projects = [
    {
      tags: ['EFTs', 'Stock', 'Cash', 'Treasury bills'],
    },
  ];

  const addTag = useCallback(
    (tag) => () => {
      if (!tags.includes(tag)) {
        const newTags = [...tags, tag];
        setTags(newTags);
        onTagsChange(newTags); 
      }
    },
    [tags, onTagsChange]  
  );

  const deleteTag = useCallback(
    (tag) => () => {
      const newTags = tags.filter((t) => t !== tag);
      setTags(newTags);
      onTagsChange(newTags);  
    },
    [tags, onTagsChange]  
  );
  

  const matchTags = (current, target) => {
    return target.every((tag) => current.includes(tag));
  };

  return (
    <div>
      <p className='tag-filter inline-p'>What are your favorite financial products?</p>
      <div className='tags-container'>
        <div>
          {tags.length > 0 &&
            tags.map((tag) => (
              <button
                key={`close-button-${tag}`} 
                className='close'
                onClick={deleteTag(tag)}
              >
                {tag} &nbsp; x
              </button>
            ))}
        </div>
        {projects
          .filter((proj) => matchTags(proj.tags, tags))
          .map((project, index) => (
            <div key={`card-${index}`} className='card'>
              {project.tags.map((tag) => (
                <button
                  key={`add-button-${tag}`} 
                  type='button'
                  onClick={addTag(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default TagFloat;
