import CashFlowCard from "./CashFlowCard";
import styles from "./CompanyDataComponents.module.css";

function CashFlowCards({data}) {
    return (
      <div>
        <div className={styles.cashFlowcardContainer}>
            <CashFlowCard title="operatingCashFlow" content={data.operatingCashflow}/>
            <CashFlowCard title="Liabilities" content={data.changeInOperatingLiabilities}/>
            <CashFlowCard title="Assets" content={data.changeInOperatingAssets}/>
            <CashFlowCard title="profitLoss" content={data.profitLoss}/>
        </div>

        <div className={styles.cashFlowcardContainer}>
            <CashFlowCard title="Expenditures" content={data.capitalExpenditures}/>
            <CashFlowCard title="Liabilities" content={data.changeInOperatingLiabilities}/>
            <CashFlowCard title="Assets" content={data.changeInOperatingAssets}/>
            <CashFlowCard title="profitLoss" content={data.profitLoss}/>
        </div>
      </div>
    );
}
export default CashFlowCards;
