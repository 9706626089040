import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import FinancialInsights from "../../components/FinanceAI/FinancialInsights"
import ProgressBar from "../../components/FinanceAI/loadingProgress"
import "./FinanceAIPage3.module.css";

import { useNavigate, useLocation } from 'react-router-dom';  


function FinanceAIPage3() {

    const location = useLocation();

    const[formData] = useState(location.state || {});
        useEffect(() => {
        // console.log('Form data updated:', formData);
    }, [formData]);

    const [loadingProgress, setLoadingProgress] = useState(0);

    const navigate = useNavigate();  

    useEffect(() => {
        const interval = setInterval(() => {
            if (loadingProgress < 100) {
                setLoadingProgress(prevProgress => prevProgress + 5);
            } else {
                clearInterval(interval);
                navigate('/financeai4', { state: { data: formData } });
            }
        }, 100);

        return () => clearInterval(interval);
    }, [loadingProgress,  navigate, formData]); 

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.mainArea}>
                <div className={styles.sidebar}>
                    <Sidebar />
                </div>
                <div className={styles.mainContent}>
                    <FinancialInsights/>
                    <div style={{ 
                            marginLeft: '50px',
                            width: '90%',
                            maxWidth: '2000px',
                            height: '550px',
                            backgroundColor: "#fff", 
                            padding: "25px",  
                            borderRadius: "8px", 
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.13)",

                            display: 'flex',
                            flexDirection: 'column',
                        }}>

                            <div style={{ marginBottom: '20px', width: '100%' }}>
                                <h2 style={{ color: '#4B0082', fontSize: '32px', textAlign: 'center' }}>Personalized Financial Report</h2>
                            </div>

                            <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '-150px',  
                                }}> 
                                
                                <div style={{
                                padding: '10px', 
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#ffffff',
                                    }}>
                                    <ProgressBar loadingProgress={loadingProgress} />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinanceAIPage3;
