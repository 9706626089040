import axios from 'axios';
import React from 'react';
import config from "../../config/config.js"
import { useSelector } from "react-redux";
import { HARDCODE_USER } from "../../constants/constants";

function TextInput({text,setText,setLoading}){

    const email = useSelector((state) => state.auth.email|| HARDCODE_USER.email);

    const handleTextChange = async(e) =>{
        setText(e.target.value);
    }

    const handleClick = async()=>{
        setLoading(true);
        setText(text + ' .Please summaraize it');
        //old version without using sqs
        // const url = `${config.BACKEND_ENDPOINT}/api/v1/summary/chat`; 
        // const responese = await axios.post(url,{role:'user', content:text})

        //with sqs
        const url = `${config.BACKEND_ENDPOINT}/api/v1/orderV2/send/1`;
        const responese = await axios.post(url,{ user_email: email, type:"summraize", context: text});
        
        setText(responese.data.choices[0].message.content);
        setLoading(false);
    }
    return <div className='textExtractContainer '>
        <textarea rows="30" cols="80" value={text} onChange={e=> handleTextChange(e)}></textarea>
        <div>
            <button onClick={handleClick}>Summary</button>
        </div>
    </div>
}

export default TextInput;