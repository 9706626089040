import React, { useState } from "react";
import axios from "axios";
import styles from "./DetectDocument.module.css"; 
import { v4 as uuidv4 } from "uuid";
import config from "../../config/config.js";
import Fileicon from "../../asset/File.svg";
import Checkicon from "../../asset/check.svg";

function FileUpload({
  setText,
  loading,
  setLoading,
  updateFileUploadedStatus,
  updateParentPresignedUrl,
}) {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    processFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const selectedFile = files[0];
    processFile(selectedFile);
  };

  const processFile = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/tiff",
    ];
    if (file && allowedTypes.includes(file.type.toLowerCase())) {
      const name_ext = file.name.split(".");
      const newFileName = name_ext[0] + "-" + uuidv4() + "." + name_ext[1];
      const renamedFile = new File([file], newFileName, { type: file.type });

      setFile(renamedFile);
      setErrorMessage(null);
      updateFileUploadedStatus(true);
    } else {
      setErrorMessage("Please upload a JPEG, PNG, PDF, or TIFF file.");
      setFile(null);
      updateFileUploadedStatus(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      console.error("No file selected.");
      return;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    const url = `${config.BACKEND_ENDPOINT}/api/v1/documents/detection`;

    axios
      .post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setLoading(false);
        setText(response.data.file_content || "");

        if (response.data.presigned_download_url) {
          updateParentPresignedUrl(response.data.presigned_download_url);
        } else {
          console.error("Did not receive presignedUrl in the response.");
        }
      });
  };

  return (
    <div className={styles.textDisplayEditorContainer}> 
      <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
        <div className={styles.dropYourFileText}>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {!file ? (
            <>
              <div className={styles.dropAndSpacing}>Drop your file here</div>
              <div className={styles.orSpacing}>OR</div>
              <div className={styles.chooseFileDiv}>
                <div className={styles.cFInnerDiv}>
                  <img src={Fileicon} alt="File icon" />
                  <input type="file" onChange={handleFileChange} />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.centeredElement}>
              <div className={styles.uploadedContainer}>
                <div className={styles.fileNameCenter}>
                  File: {file.name}
                  <img
                    src={Checkicon}
                    alt="Uploaded"
                    className={styles.checkIcon}
                  />
                </div>
              </div>
              <button onClick={handleSubmit} disabled={!file}>
                {loading ? "Analyzing..." : "Analyze"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
