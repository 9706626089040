import styles from "./CompanyDataComponents.module.css";
import Histogram from "./Histogram";
import Pie from "./Pie";
import Cards from "./Cards";
import CashFlowCards from "./CashFlowCards";
const AnnualRepot=({companyName,annualReportData})=>{
    const DataForFistCircle = [
        { x: "Long Term", y: annualReportData.longTermInvestments },
        { x: "Short Term", y: annualReportData.shortTermInvestments },
      ];
    const DataForSecondCircle = [
        { x: "Current", y: annualReportData.currentDebt },
        { x: "Long Term", y: annualReportData.shortTermDebt },
        { x: "Short Term", y: annualReportData.longTermDebt},
      ];
    return (
       <div>
            <h1 className={styles.title}>{companyName} Latest Annual Report</h1>
            <h4 className={styles.subtitle}>Income Statement</h4>
            <div>
                <Cards grossProfit ={annualReportData.grossProfit} totalRevenue ={annualReportData.totalRevenue} 
                costOfRevenue={annualReportData.costOfRevenue} operatingIncome={annualReportData.operatingIncome}
                netIncome={annualReportData.netIncome}/>
            </div>
            <h4 className={styles.subtitle}>Balance Sheet</h4>
            <div className={styles.cardContainer}>
                <div className={styles.chartContainer}>
                <Histogram currentAssets={annualReportData.totalCurrentAssets}
                    inventory={annualReportData.inventory} intangibleAssets={annualReportData.intangibleAssets}
                    goodwill={annualReportData.goodwill}/>
                </div>
                <Pie outerData={DataForFistCircle} type={"Investments"}/>
                <Pie outerData={DataForSecondCircle} type={"Debt"}/>
            </div>
            <h4 className={styles.subtitle}>Cash Flows</h4>
            <CashFlowCards data = {annualReportData}/>
       </div>
        
    )
}

export default AnnualRepot;