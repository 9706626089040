import React from 'react';
import styles from "./CompanyDataComponents.module.css";
function Card({ title, content, bgColor,Image  }) {

  const cardStyle = {
    backgroundColor: bgColor,
  };

  const containerStyle = {
    position: 'relative',
    width: '25px', // 容器宽度
    height: '25px', // 容器高度
    backgroundColor: 'black', // 容器背景色
    borderRadius: '50%', // 使容器成为圆形
    overflow: 'hidden', // 防止内容溢出容器
    display: 'flex', // 使用 flex 布局
    justifyContent: 'center', // 水平居中
    alignItems: 'center' // 垂直居中
  };

  const imageStyle = {
    width: '80%', // 图片宽度相对于容器的百分比
    height: '80%', // 图片高度相对于容器的百分比
    objectFit: 'cover', // 调整图片以覆盖指定的区域
  };

  return (
    <div className={styles.card} style={cardStyle}>
      <div style={containerStyle}>
        <img src={Image} alt="Inner" style={imageStyle} />
      </div>
      <p>{title}</p>
      <h3>{content}</h3>
      <p>USD</p>
    </div>
  );
}

export default Card;