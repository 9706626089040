import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { HARDCODE_USER } from "../../constants/constants";
import "./companyDataPage.css";


import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../TextExtractPage/TextExtractPage.module.css";
import { Link } from "react-router-dom";
import CompanySearchBar from "../../components/CompanyData/CompanySearchBar";
import CompanyInfo from "../../components/CompanyData/CompanyInfo";
import CompanyTable from "../../components/CompanyData/CompanyTable";
import axios from "axios";
import config from "../../config/config.js"

const CompanyDataPage = () => {
    const user = useSelector((state) => state.auth.user || HARDCODE_USER);
    const credit = useSelector((state) => state.auth.credit);
    const [company,setCompany] = useState("table");
    const [showReport, setShowReport] = useState(false);
    const [reportData, setreportData] = useState(null);
    const [, setError] = useState(null);

    const handleSearch = async(companyName)=>{
        axios.post(`${config.BACKEND_ENDPOINT}/api/v1/financial/company/${companyName}`)
        .then(response => {
          if (response.data.error_message) {
            throw new Error(response.data.error_message);
          }
          setreportData(response.data.financial_data);
          setShowReport(true);
          setCompany(companyName);
        })
        .catch(error => {
          setError(error.message);
          console.error('Error fetching data:', error);
        });
    }

    return (<div className={styles.container}>
            <Header />
            <div className={styles.mainArea}>
            <div className={styles.sidebar}>
                <Sidebar userCredit={credit} userName={user.username}  />
            </div>
            <div className={styles.textExtractPageArea}>
                {/*  Navigation links topleft */}
                <div className={styles.dashboardText}>
                    <Link to="/console" className={styles.dashboardLink}>
                        Dashboard
                    </Link>
                    <span>/</span>
                    <Link to="/financial/company" className={styles.textracterLink}>
                        CompanyData
                    </Link>
                </div>
                <div>
                    <CompanySearchBar placeholder="Search Company" handleSearch={handleSearch}/>
                </div>
                <div>
                {showReport ? <CompanyInfo companyName={company} annualReportData = {reportData} className={styles.dashboardText}/> : <CompanyTable handleSearch={handleSearch}/>}
                </div>
            </div>
            
            </div>
        </div>
        ) 
};

export default CompanyDataPage;