import React from "react";
import styles from "./AnalyzeExpense.module.css";
import DownloadButton from "./DownloadButton";

function FileResponse({ text, presignedUrl }) {

  function parseCsvData(Text) {
    const lines = Text.split("\n").slice(1); // 去掉标题行
    const data = [];

    lines.forEach(line => {
        const fields = line.split(",");
        if (fields.length >= 4) {
            const Type = fields[2].replace(/^"|"$/g, '').trim();
            const Value = fields[3].replace(/^"|"$/g, '').trim();
            const Label = fields[1].replace(/^"|"$/g, '').trim();
            const note = Label ? `(${Label})` : "";
            if (Type && Value) {
                data.push({ type: Type, value: Value, note: note });
            }
        }
    });

    return data;
}
  const data = parseCsvData(text);

  return (
    <div className={styles.tableDisplayContainer}>
      <div className={styles.dashboardText}>
        <table className={styles.dataTable}>
            <tbody>
                {data.map((row, idx) => (
                    <tr key={idx}>
                        <td>{row.type}</td>
                        <td>{row.value}</td>
                        <td>{row.note}</td>
                    </tr>
                ))}
            </tbody>
        </table>
      </div>
      <div className={styles.downloadButtonContainer}>
        <DownloadButton presignedUrl={presignedUrl} />
      </div>
    </div>
);
}

export default FileResponse;
