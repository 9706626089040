import React from "react";
import styles from "./DetectDocument.module.css";
import DownloadButton from "./DownloadButton";

function FileResponse({ text, presignedUrl }) {
  return (
    <div className={styles.wrap}>
      <h3>Extracted Document Content:</h3>
      <div className={styles.textDisplayEditorContainer}>
        <pre>{text}</pre>
      </div>
      <div className={styles.downloadButtonContainer}>
        <DownloadButton presignedUrl={presignedUrl} />
      </div>
    </div>
  );
}

export default FileResponse;
