import React, { useState } from "react";
import FileUpload from "../../components/AnalyzeExpense/FileUpload";
import FileResponse from "../../components/AnalyzeExpense/FileResponse";
import Loading from "../../components/transcribe/loading";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import styles from "./AnalyzeExpensePage.module.css";

const AnalyzeExpensePage = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState(null);
   // eslint-disable-next-line no-unused-vars
  const [fileUploadedStatus, setFileUploadedStatus] = useState(false);


  let showcontent;

  if (loading) {
    showcontent = <Loading />;
  } else if (presignedUrl) {
    showcontent = <FileResponse text={text} presignedUrl={presignedUrl} />;
  } else {
    showcontent = (
      <FileUpload
        setText={setText}
        setLoading={setLoading}
        loading={loading}
        updateParentPresignedUrl={setPresignedUrl}
        updateFileUploadedStatus={setFileUploadedStatus}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.mainArea}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
        <div className={styles.analyzeExpensePageArea}>
          <div className={styles.dashboardText}>
            <Link to="/console" className={styles.dashboardLink}>
              Dashboard
            </Link>
            <span>/</span>
            <Link to="/analyze-expense" className={styles.analyzeExpenseLink}>
              Receipt to CSV
            </Link>
          </div>
          <div className={styles.analyzeExpenseContainer}>
            <h1 className={styles.defaultH1}>Receipt to CSV</h1>
            <h2 className={styles.defaultH2}>
           Effortlessly Convert Your Receipts into CSV! (Accepted file formats: JPEG, PNG, PDF, TIFF))
            </h2>
            {showcontent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeExpensePage;
