import React from 'react';
import { VictoryPie} from 'victory';
import styles from "./CompanyDataComponents.module.css";

function Pie({outerData,type}) {
  const total = outerData.reduce((sum, data) => sum + data.y, 0);

  // Define unique colors for each data point
  const uniqueColors = ["#30DAFF", "#853EFA", "#08D110"];
  const remainingColor = "#EDEBF0"; // Color for the remaining parts

  const chartStyles = [
    { innerRadius: 70, radius: 90 },
    { innerRadius: 45, radius: 65 },
    { innerRadius: 20, radius: 40 }
  ];

  return (
    <div>
      <div className= {styles.flex_container}>
      <div style={{ position: "relative", width: "300px", height: "300px" }} className={styles.flex_item}>
        {outerData.map((data, index) => {
          const chartData = [
            { x: data.x, y: data.y },
            { x: 'Remaining', y: total - data.y }
          ];

          return (
            <div key={index} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
              <VictoryPie
                data={chartData}
                colorScale={[uniqueColors[index], remainingColor]}
                innerRadius={chartStyles[index].innerRadius}
                radius={chartStyles[index].radius}
                labels={() => null}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.flex_item_right}>
        <div className={styles.custom_legend}>
          {outerData.map((item, index) => (
            <div key={index} className={styles.legend_item}>
              <span className={styles.legend_color} style={{ backgroundColor: uniqueColors[index] }}></span>
              <span className={styles.legend_text_color}>{item.x}<br/></span>
              <span></span>
              <div className={styles.legend_text}>
                <span>{item.y}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: '-13px' }}>
    {type}
    </div>
    </div>
  );
}

export default Pie;