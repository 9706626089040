import React from 'react';
import backgroundImage from '../../asset/FinancialDatabackground.jpg';

function CashFlowCard({ title, content }) {
  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '250px',
    height: '70px',
    marginBottom: '15px'
  };

  return (
    <div style={cardStyle}>
      <h2>$ {content}</h2>
      <p>{title}</p>
    </div>
  );
}

export default CashFlowCard;