import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import FileUpload from "../../components/DetectDocumentText/FileUpload";
import FileResponse from "../../components/DetectDocumentText/FileResponse";
import Loading from "../../components/transcribe/loading";
import { Link } from "react-router-dom";
import styles from "./DetectDocumentTextPage.module.css";

const DetectDocumentTextPage = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fileUploadedStatus, setFileUploadedStatus] = useState(false);




  let showcontent;

  if (loading) {
    showcontent = <Loading />;
  } else if (presignedUrl) {
    showcontent = <FileResponse text={text} presignedUrl={presignedUrl} />;
  } else {
    showcontent = (
      <FileUpload
        setText={setText}
        // setError={setError}
        setLoading={setLoading}
        updateParentPresignedUrl={setPresignedUrl}
        updateFileUploadedStatus={setFileUploadedStatus}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.mainArea}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
        <div className={styles.detectDocumentTextPageArea}>
          <div className={styles.dashboardText}>
            <Link to="/console" className={styles.dashboardLink}>
              Dashboard
            </Link>
            <span>/</span>
            <Link to="/detect-document-text" className={styles.detectorLink}>
              Document Text Detector
            </Link>
          </div>
          <div className={styles.detectDocumentContainer}>
            <h1 className={styles.defaultH1}>Document Text Detector</h1>
            <h2 className={styles.defaultH2}>
            Extract text from documents(Accepted file formats: JPEG, PNG, PDF, TIFF)
            </h2>
            {showcontent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetectDocumentTextPage;
