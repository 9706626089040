import React from 'react';
import styles from "./MoodBar.module.css";

function MoodBar() {
  return (
    <div className={styles.moodContainer}>
      <div className={styles.bullish}>Bullish</div>
      <div className={styles.somewhatBullish}>Somewhat-Bullish</div>
      <div className={styles.neutral}>Neutral</div>
      <div className={styles.somewhatBearish}>Somewhat-Bearish</div>
      <div className={styles.bearish}>Bearish</div>
    </div>
  );
}

export default MoodBar;
